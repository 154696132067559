import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" description="Vous retrouvez sur cette page les différentes façons de me contacter." />
    <div className="py-12 mt-8 flex flex-col items-center">
      <StaticImage className="h-48 w-48 rounded-full" src="../images/maman.jpg" alt="" />

      <div className="mt-4 text-5xl font-extrabold">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-primary-900">
          Anne Marie Sow
        </span>
      </div>
    </div>
  </Layout>
);
export default ContactPage;
